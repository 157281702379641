/**
 * List of Constant
 *
 * @public
 */
class Constants {
    /**
     * Channel Media Type Chat
     *
     * @type {String}
     */
    static MEDIA_TYPE_CHAT = 'CHAT';

    /**
     * Channel Media Type Videochat
     *
     * @type {String}
     */
    static MEDIA_TYPE_VIDEOCHAT = 'VIDEOCHAT';

    /**
     * LIVE signal message CONNECTED
     *
     * @type {String}
     */
    static SIGNAL_CONNECTED = 'CONNECTED';

    /**
     * LIVE signal message DISCONNECTED
     *
     * @type {String}
     */
    static SIGNAL_DISCONNECTED = 'DISCONNECTED';

    /**
     * LIVE signal message TYPINGSTARTED
     *
     * @type {String}
     */
    static SIGNAL_TYPINGSTARTED = 'TYPINGSTARTED';

    /**
     * LIVE signal message TYPINGENDED
     *
     * @type {String}
     */
    static SIGNAL_TYPINGENDED = 'TYPINGENDED';

    /**
     * LIVE signal message HOLD
     *
     * @type {String}
     */
    static SIGNAL_HOLD = 'HOLD';

    /**
     * LIVE signal message OFFHOLD
     *
     * @type {String}
     */
    static SIGNAL_OFFHOLD = 'OFFHOLD';

    /**
     * LIVE signal message AUDIOMUTED
     *
     * @type {String}
     */
    static SIGNAL_AUDIOMUTED = 'AUDIOMUTED';

    /**
     * LIVE signal message AUDIOUNMUTED
     *
     * @type {String}
     */
    static SIGNAL_AUDIOUNMUTED = 'AUDIOUNMUTED';

    /**
     * LIVE signal message VIDEOMUTED
     *
     * @type {String}
     */
    static SIGNAL_VIDEOMUTED = 'VIDEOMUTED';

    /**
     * LIVE signal message VIDEOUNMUTED
     *
     * @type {String}
     */
    static SIGNAL_VIDEOUNMUTED = 'VIDEOUNMUTED';

    /**
     * LIVE signal message SNAPSHOTTED
     *
     * @type {String}
     */
    static SIGNAL_SNAPSHOTTED = 'SNAPSHOTTED';

    /**
     * LIVE signal message SNAPSHOTTED
     *
     * @type {String}
     */
     static SIGNAL_TEMPFILE_INFO = 'TEMPFILE_INFO';

    /**
     * LIVE signal message NEWDOCUMENT
     *
     * @type {String}
     */
    static SIGNAL_NEWDOCUMENT = 'NEWDOCUMENT';

    /**
     * LIVE signal message DELDOCUMENT
     *
     * @type {String}
     */
    static SIGNAL_DELDOCUMENT = 'DELDOCUMENT';

    /**
     * LIVE signal message SCRIPTER
     *
     * @type {String}
     */
    static SIGNAL_SCRIPTER = 'SCRIPTER';

    /**
     * LIVE signal message ADDINGVIDEO
     *
     * @type {String}
     */
    static SIGNAL_ADDINGVIDEO = 'ADDINGVIDEO';

    /**
     * LIVE signal message RDSERROR
     *
     * @type {String}
     */
    static SIGNAL_RDSERROR = 'RDSERROR';

    /**
     * LIVE signal message RDSUPDATE
     *
     * @type {String}
     */
    static SIGNAL_RDSUPDATE = 'RDSUPDATE';

    /**
     * LIVE signal message TOGGLEFRAME
     * 
     * @type {String}
     */
    static SIGNAL_TOGGLEFRAME = 'TOGGLEFRAME';

    /**
     * LIVE message category SIGNAL
     *
     * @type {String}
     */
    static MSG_CAT_SIGNAL = 'SIGNAL';

    /**
     * LIVE message category INFO
     *
     * @type {String}
     */
    static MSG_CAT_INFO = 'INFO';

    /**
     * LIVE message category VIDEO
     *
     * @type {String}
     */
    static MSG_CAT_VIDEO = 'VIDEO';

    /**
     * Message Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_MESSAGE = 'MESSAGE';

    /**
     * Send file Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_SEND_FILE = 'sendFile';

    /**
     * Send snapshot IM Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_SEND_SNAPSHOT_IM = 'sendSnapshotIM';

    /**
     * Send snapshot FS Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_SEND_SNAPSHOT_FS = 'sendSnapshotFS';

    /**
     * Get files list for customer Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_FILES = 'getFiles';

    /**
     * Get file url Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_FILE_URL = 'getFileUrl';

    /**
     * Get files list for customer Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_FOLDER_FILES = 'getFolderFiles';

    /**
     * Get upload key Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_UPLOAD_KEY = 'getUploadKey';

    /**
     * Get WebRTC TURN info REQUEST / RESPONSe
     *
     * @type {String}
     */
    static REQ_GET_WEBRTC_TURN = 'getWebrtcTurn';

    /**
     * Message Websocket get user REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_USER = 'getUser';

    /**
     * Message Websocket info log REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_INFO_LOG = 'infoLog';

////////////////////////////////
    /**
     * Get plans list Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_PLAN_LIST = 'getPlanList';

    /**
     * Get FreeSwitch login REQUEST / RESPONSe
     *
     * @type {String}
     */
    static REQ_GET_FS_LOGIN = 'getFSLogin';
//////////////





////////////////////////////////
///////
/////////////////////////////////////////////////////////
//////
/////////////////////
///////
//////////////////////////////////////////////////////////

///////
///////////////////////////////////////////////////
//////
/////////////////////
///////
/////////////////////////////////////////////////

///////
///////////////////////////////////////////
//////
/////////////////////
///////
/////////////////////////////////////////////

///////
///////////////////////////////////////////
//////
/////////////////////
///////
/////////////////////////////////////////////
//////////////



    
//////////////////////////////////////////
    /**
     * Check if bot is implemented
     *
     * @type {String}
     */
    static REQ_BOT_IS_IMPLEMENTED = 'getBotIsImplemented';

    /**
     * Register to bot
     *
     * @type {String}
     */
    static REQ_BOT_REGISTER = 'botRegister';

    /**
     * Send message to bot
     *
     * @type {String}
     */
    static REQ_BOT_SEND_MESSAGE = 'botSendMessage';
//////////////




    
/////////////////////////////////////////////////
    /**
     * Get cobrowsing key Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_COBROWSING_KEY = 'getCobrowsingKey';

    /**
     * LIVE signal message COBROWSING_START
     *
     * @type {String}
     */
    static SIGNAL_COBROWSING_START = 'COBROWSING_START';

    /**
     * LIVE signal message COBROWSING_CONNECTED
     *
     * @type {String}
     */
    static SIGNAL_COBROWSING_CONNECTED = 'COBROWSING_CONNECTED';

    /**
     * LIVE signal message COBROWSING_DISCONNECTED
     *
     * @type {String}
     */
    static SIGNAL_COBROWSING_DISCONNECTED = 'COBROWSING_DISCONNECTED';

    /**
     * LIVE signal message COBROWSING
     *
     * @type {String}
     */
    static SIGNAL_COBROWSING = 'COBROWSING';

    /**
     * LIVE signal message COBROWSING_STOP
     *
     * @type {String}
     */
    static SIGNAL_COBROWSING_STOP = 'COBROWSING_STOP';
//////////////





//////////////////////////////////////////////////////
    /**
     * Get direct messages Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_DIRECT_MESSAGE_LIST = 'requestDirectMessageList';

    /**
     * Get direct messages Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_DIRECT_MESSAGE_BODY = 'requestDirectMessageBody';

    /**
     * Get direct messages Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_DIRECT_MESSAGE_SEEN = 'requestDirectMessageSeen';

    /**
     * Get direct messages Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_DIRECT_MESSAGE_GROUP = 'requestDirectMessageGroup';

    /**
     * Get direct messages Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_DIRECT_MESSAGE_NEW = 'requestDirectMessageNew';
//////////////





/////////////////////////////////////////////
    /**
     * Get surveys Websocket REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_GET_SURVEYS = 'getSurveys';
//////////////




/////////////////////////////////////////////////
    /**
     * RDS create user REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_CREATE_USER = 'requestRdsCreateUser';

    /**
     * RDS RSA public key REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_RSA_PUBLIC_KEY = 'requestRdsRsaPublicKey';

    /**
     * RDS cert check REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_CERT_CHECK = 'requestRdsCertCheck';

    /**
     * RDS cert issue REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_CERT_ISSUE = 'requestRdsCertIssue';

    /**
     * RDS cert revoke REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_CERT_REVOKE = 'requestRdsCertRevoke';

    /**
     * RDS get contract REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_GET_CONTRACT = 'requestRdsGetContract';

    /**
     * RDS sign contract REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_SIGN_CONTRACT = 'requestRdsSignContract';

    /**
     * RDS sign documents REQUEST / RESPONSE
     *
     * @type {String}
     */
    static REQ_RDS_SIGN_DOCUMENTS = 'requestRdsSignDocuments';
//////////////

}
export default Constants;
