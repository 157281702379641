module.exports=function(scope){ return `${scope.drawer ? `
    <div class="mdl-layout mdl-js-layout ${scope.drawerFixed}">
        <div class="mdl-layout__drawer">`
: ''}

<div class="mdl-card__actions mdl-card--border asseco-chat-window-tbar">
    <form enctype="multipart/form-data" method="post" style="margin: 0; padding: 0;">
        ${scope.tranDownload ? `
            <button id="asseco-chat-window-downloadTranscript" class="mdl-button mdl-button--icon">
                ${scope.tranDownloadIcon}
            </button>`
        : ''}

        ${scope.fileUpload ? `
            <input id="asseco-chat-window-selectFile" type="file" name="selectFile" style="display: none;">
            <button id="asseco-chat-window-uploadFile" class="mdl-button mdl-button--icon">
                ${scope.fileUploadIcon}
            </button>`
        : ''}

        ${scope.addVideo ? `
            <button id="asseco-chat-window-addVideo" class="mdl-button mdl-button--icon">
                ${scope.addVideoIcon}
            </button>`
        : ''}

        ${scope.isBot ? `
            <button id="asseco-chat-window-callAgent" class="mdl-button mdl-button--icon">
                ${scope.callAgentIcon}
            </button>
        ` : ''}
    </form>
</div>

<ul class="mdl-list asseco-chat-window-list">
    ${scope.initMessage}
</ul>

<div class="mdl-card__actions mdl-card--border asseco-chat-window-ibar">
    <div id="asseco-chat-window-typing" class="asseco-chat-window-typing-end">
        <span>
            <svg class="typing-pen" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
            </svg>
        </span>
    </div>
    ${scope.fileUpload ? `
        <div id="asseco-chat-window-upload">
            <progress id="asseco-chat-window-progressBar" value="0" max="100" style="width: 99%;"></progress>
        </div>`
    : ''}
</div>

<div class="mdl-card__actions mdl-card--border asseco-chat-window-bbar">
    <div class="mdl-textfield mdl-js-textfield asseco-chat-window-input" style="float: left; width: calc(100% - 95px);">
        <textarea class="mdl-textfield__input" type="text" rows="2" id="${scope.textInId}" style="height: 66px;"></textarea>
        <label class="mdl-textfield__label" for="asseco-chat-window-text">${scope.textTip}...</label>
    </div>
    <div class="asseco-chat-window-send" style="float: right; padding: 20px 0;">
        <button class="mdl-button mdl-button--colored">${scope.textSend}</button>
    </div>
    <div style="clear: both;"></div>
</div>

${scope.drawer ? `
        </div>
        <main class="mdl-layout__content"></main>
    </div>`
: ''}

<audio id="asseco-chat-msg-sound" src="${scope.msgSound}" />
`};